/* Particles */

.particles {
    transition: background-color 0.5s linear;
    position: absolute;
    background-color: var(--primaryBackgroundColor);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: 0;
}
