/* light mode */

html {
    overflow: hidden;
    --primaryTextColor: #000;
    --primaryBackgroundColor: #FFF;
    --secondaryTextColor: #333;
    --shadowColor: rgba(255, 255, 255, 0.5);
    --tertiaryTextColor: #777;
}

/* default: dark mode */

html.other {
    overflow: hidden;
    --primaryTextColor: #FFF;
    --primaryBackgroundColor: #000;
    --secondaryTextColor: #CCC;
    --shadowColor: rgba(0, 0, 0, 0.5);
    --tertiaryTextColor: #888;
}

/* Toggle */

main {
    position: fixed;
    z-index: 1;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

main:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

#toggle {
    display: none;
}

.switch {
    cursor: pointer;
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.75rem;
    background-color: var(--shadowColor);
    border-radius: 25%;
    box-shadow: 0 0 0.25rem 0.25rem rgba(128, 128, 128, 0.2);
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s linear;
    font-size: 0.5rem;
}
